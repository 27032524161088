<template>
  <div>
    <b-card class="col-md-8 ">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Wallet Recharge to user: {{ $route.params.username }}</span>
          </template>

          <!-- clase full width -->
          <!-- <b-button-group size="md" class="col-md-12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="typeTransaction == 'credit' ? 'success' : 'outline-secondary'"
              @click="typeTransaction = 'credit'"
            >
              Credit
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="typeTransaction == 'debit' ? 'danger' : 'outline-secondary'"
              @click="typeTransaction = 'debit'"
            >
              Debit
            </b-button>
          </b-button-group> -->

          <div class="row mt-3">
            <div
              class="
                col-md-12
                text-center
                d-flex
                align-items-end
                justify-content-center
              "
            >
              <h4>Current balance:</h4>
              <h3 class="ml-1">
                {{ walletSelect.balance.balance | currency({ symbol: walletSelect.balance.currency }) }}
              </h3>
            </div>
          </div>

          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Currencies"
                    v-if="walletSelect.currencies.length > 0"
                  >
                    <v-select
                      :value="walletSelect.balance.currency"
                      placeholder="Currencies"
                      label="currency"
                      :options="walletSelect.currencies"
                      :reduce="(item) => item.currency"
                      @input="getBalanceMod({ currency: $event, walletId: walletSelect._id })"
                      :searchable="false"
                      :clearable="false"
                      name="currency"
                      rules="required"
                    />
                  </b-form-group>
                  <div v-else>
                      Wallet has no currencies!!!
                  </div>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Amount to recharge">
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                      ref="AmountInput"
                    >
                      <b-form-input
                        type="number"
                        v-model="transaction.amount"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Amount: 0"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Description">
                    <validation-provider #default="{ errors }" name="description">
                      <b-form-input
                        v-model="transaction.details.description"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- <b-form-group label="Type">
                    <validation-provider #default="{ errors }" name="type">
                      <b-form-input
                        v-model="transaction.details.type"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->
                  <!-- comentario -->
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="confirmeTransaction"
                  >
                    <feather-icon icon="CheckIcon" size="16" class="mr-1" />
                    Recharge
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '../userStoreModule'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    integer,
    modalShow: false,
    typeTransaction: "credit",
    transaction:{
        amount: null,
        reference: null,
        details: {
            description: '',
            type: '',
        }
    },  
    walletSelect: {
        balance: {
            balance: 0,
            currency: '',
        },
        currencies: [],
    },
  }),
  watch: {
    modalShow(val) {
        if(val){
            setTimeout(() => {
            this.$refs.AmountInput.$el.querySelector('input').focus();
            }, 200);
        }
    },
  },
  methods: {
      showCreditDebitMod({wallet}){
        // commit wallet select
        this.$store.commit("app-user/SELECT_WALLET", wallet);
        this.modalShow = true;
      },
   async confirmeTransaction() {
       // validar formulario    
        const validate = await this.$refs.simpleRules.validate();
        if (!validate) return;

        //  pregustar si esta seguro de realizar la transaccion
        const confirme = await this.$swal({
            title: "Are you sure to carry out the operation?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-primary mr-1",
                cancelButton: "btn btn-danger ml-1",
            },
        })
        // si esta seguro se ejecuta la transaccion
        if (confirme.value) this.setCreditOrDebit();

        // si no esta seguro se cierra el confirm
    },

    async setCreditOrDebit() {
        try{
            // se reune los datos de la transaccion
            const payload = {
                walletId: this.walletSelect._id,
                typeTransaction:this.typeTransaction,
                currency: this.walletSelect.balance.currency,
                transaction: this.transaction,
            }
            // procesar la operacion
            await this.$store.dispatch('app-user/setCreditOrDebit', payload);

            // preguntar si desea realizar otros operacion
            this.otherOperation();

        }catch(error){
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error!',
                    icon: 'XIcon',
                    text: error.message,
                    variant: 'danger',
                }
            });            
        }
    },
   async otherOperation() {
        // mostrar mensaje de exito y preguntar si desea realizar otra operacion
        const otherOperation = await this.$swal({
            title: "Successful operation",
            text: "Do you want to carry out another operation?",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No, thanks",
            customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
        })

        // limpiar el formulario
        this.resetForm();  

        if(otherOperation.value){
            // en caso de que desee realizar otra operacion se consulta el balance y focus en el input amount
            setTimeout(() => {
                this.$refs.AmountInput.$el.querySelector('input').focus();
            }, 600);

            const payload = {
                walletId: this.walletSelect._id,
                currency: this.walletSelect.balance.currency,
            }
            this.getBalanceMod(payload);
        }else{
            // en caso de no desear realizar otra operacion se cierra el modal y se lista las wallets
            this.$router.push({name: 'apps-users-list'});
        }
    },
    resetForm() {
        this.typeTransaction = "credit",
        this.transaction = {
            amount: null,
            reference: null,
            details: {
                description: '',
                type: '',
            }
        }
        //  reset validate 
        this.$refs.simpleRules.reset();
    },
    async getBalanceMod({currency, walletId}) {
        try{
          const res = await this.$store.dispatch("app-user/fetchUserWallet", {currency, walletId});
          this.walletSelect.balance.balance = res.data.balance;
          this.walletSelect.balance.currency = currency;
        }catch(error){
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error!',
                    icon: 'XIcon',
                    text: error.message,
                    variant: 'danger',
                }
            });
        }
    },
    async getWalletUser(){
        try{
          // paramas router username
          const username = this.$route.params.username; 
          const res = await this.$store.dispatch("app-user/walletUser", {username} );
          this.walletSelect = res.data[0];
        }catch(error){
            console.log(error);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error!',
                    icon: 'XIcon',
                    text: error.message,
                    variant: 'danger',
                }
            });
        }
    }
  },

  created() {
    // register module store validar si ya esta registrado 
    if(!store.hasModule('app-user')){
        store.registerModule('app-user', userStoreModule);
    }
    this.getWalletUser()
  },
  destroyed() {
    // unregister module store
    if(store.hasModule('app-user')){
        store.unregisterModule('app-user');
    }
  },
  
};
</script>
 
 